import React, { useState } from "react";
import styled from "styled-components";
import { RowText, Input } from "../../../Widgets";
import moment from "moment";
import { Modal, DatePicker, message } from "antd";
const constants = require("../../../constants");

function Offline({ order, visible, values, onCancel, onEdit }) {
  const isMobile = window.innerWidth < constants.breakpoints.sm;
  const remittanceCompleted =
    order.remittance_account && order.remittance_number && order.remitted_time;

  const renderTips = () => {
    if (remittanceCompleted) {
      if (isMobile) {
        return "請加 Line @ssprint 聯繫客服確認付款。";
      }
      return "請加 Line @ssprint 聯繫客服確認付款。付款經客服確認且檔案上傳完成後，訂單才會正式排單審稿。";
    }
    if (isMobile) {
      return "請依照訂單金額匯款，完成後請回填匯款資訊。";
    }
    return "等待付款，請依照訂單金額（含手續費）匯款，完成後請回填匯款資訊";
  };
  return (
    <>
      <Wrapper>
        <div className="tips">{renderTips()}</div>
        <div className="container">
          <div>
            <h3>臨櫃匯款收款資訊</h3>
            <RowText
              weight="400"
              label="收款戶名"
              value="感官文化印刷有限公司"
            />
            <RowText
              weight="400"
              label="收款代號"
              value="808 玉山銀行-古亭分行"
            />
            <RowText weight="400" label="收款帳號" value="0989-940-014290" />
          </div>
          <div>
            <h3>回填匯款資訊</h3>
            <RowText
              weight="400"
              label="匯款戶名"
              value={order.remittance_account}
            />
            <RowText
              weight="400"
              label="匯款帳號後五碼"
              value={order.remittance_number}
            />
            <RowText
              weight="400"
              label="匯款時間"
              value={
                order.remitted_time
                  ? moment(order.remitted_time).format("YYYY-MM-DD HH:mm")
                  : ""
              }
            />
          </div>
        </div>
        <div className="notice">
          轉帳金額請與訂單金額一致，請勿自行扣除轉帳手續費
        </div>
      </Wrapper>
      <OfflineModal
        visible={visible}
        values={values}
        onCancel={onCancel}
        onEdit={onEdit}
      />
    </>
  );
}

const OfflineModal = ({ visible, values: _values, onCancel, onEdit }) => {
  const [values, _setValues] = useState(
    {
      ..._values,
      remitted_time: _values.remitted_time || new Date(),
    } || {
      remittance_account: "",
      remittance_number: "",
      remitted_time: new Date(),
    }
  );

  function setValues(obj) {
    _setValues(prev => ({ ...prev, ...obj }));
  }

  const onConfirm = async () => {
    try {
      await onEdit(values);
      onCancel();
      message.success("填寫成功，等待工作人員對帳。");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledModal
      title="臨櫃匯款"
      onCancel={onCancel}
      visible={visible}
      footer={<Footer onConfirm={onConfirm} />}
      width="700px"
    >
      <h2
        style={{
          color: constants.colors.highlight,
          fontSize: "16px",
          fontWeight: 500,
          marginBottom: "24px",
        }}
      >
        轉款完成後回填以下資訊提供對帳
      </h2>
      <InputGroup>
        <h3>匯款戶名</h3>
        <Input
          value={values.remittance_account}
          onChange={e => setValues({ remittance_account: e.target.value })}
        />
      </InputGroup>
      <InputGroup>
        <h3>匯款後五碼</h3>
        <Input
          value={values.remittance_number}
          onChange={e => setValues({ remittance_number: e.target.value })}
        />
      </InputGroup>
      <InputGroup>
        <h3>匯款時間</h3>
        <DatePicker
          defaultValue={moment(values.remitted_time)}
          onChange={(value, dateString) => {
            setValues({ remitted_time: dateString });
          }}
          format="YYYY-MM-DD HH:mm"
          showTime
          style={{
            width: 250,
            height: 40,
            border: "solid 1px #A6A6A6",
            borderRadius: 5,
          }}
        />
      </InputGroup>
    </StyledModal>
  );
};

const Footer = ({ onConfirm }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <StyledButton onClick={onConfirm}>確認</StyledButton>
    </div>
  );
};

const Wrapper = styled.div`
  & > .tips {
    color: ${constants.colors.highlight};
    font-weight: 500;
    margin: 8px 0 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  & > .container {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 500;
    padding: 24px;
    border-top: 1px solid #d8dbdf;
    border-bottom: 1px solid #d8dbdf;
    > div {
      flex: 0 0 50%;
      max-width: 50%;
      h3 {
        font-weight: bold;
        margin-bottom: 8px;
      }
    }
  }
  & > .notice {
    color: #9b9b9b;
    padding: 24px 24px 0;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 10px 30px;
    background-color: #fafafa;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: #5a5a5a;
      line-height: 1.58;
    }
  }
  .ant-modal-body {
    padding: 20px 30px;
  }
  .ant-modal-footer {
    padding: 15px 30px;
    border-top: none;
  }
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 56px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: ${constants.buttons.background.normal.primary};
  border: none;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    background-color: ${constants.buttons.background.hover.primary};
  }
`;

const InputGroup = styled.div`
  margin-bottom: 14px;
  h3 {
    font-size: 14px;
    color: #505050;
    margin-bottom: 8px;
  }
  > input {
    margin: 0;
    border-radius: 2px;
    border: solid 1px #ccc;
    padding: 10px 12px;
    font-size: 14px;
  }
`;

export default Offline;
