import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import * as Cart from "../Contexts/CartContext";
import { message } from "antd";
import {
  OrderBasicInfo,
  Logistic,
  Invoice,
  Calculation,
  Payment,
  Products,
  AfterSales,
  Extra,
} from "../Components/Order";
import Constants from "../constants";
import * as Theme from "../Theme";
import moment from "moment";
import GA from "../Utils/GA";
import Pixel from "../Utils/fbPixel";
import { Context } from "../AppContext";
import UnAuthResult from "../Components/UnAuthResult";

const queryString = require("query-string");

function OrderDetailPage({ location, profile }) {
  const app = useContext(Context);
  const { id } = queryString.parse(location.search);
  const [order, setOrder] = useState();
  const [cartData, setCartData] = useState();
  const [invoices, setInvoices] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const [status, setStatus] = useState(
    queryString.parse(location.search).status
  );

  const getOrder = useCallback(async () => {
    if (id && profile) {
      try {
        let order = await Cart.Actions.fetchOrder(id);
        setOrder(order);
        let cartData = JSON.parse(order.cart);
        setCartData(cartData);
        return order;
      } catch (ex) {}
    }
  }, [id, profile]);

  const getItems = useCallback(async id => {
    try {
      let orderItems = await Cart.Actions.getItemsByOrderId(id); // for attachment upload
      setOrderItems(orderItems);
    } catch (ex) {
      console.warn(ex);
    }
  }, []);

  const waitForOrderStatusUpdate = useCallback(async () => {
    const maxAttempts = 10;
    const delayBetweenAttempts = 1000;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      try {
        const updatedOrder = await getOrder();

        if (!updatedOrder) {
          return { status: "error", message: "無法獲取訂單訊息" };
        }

        if (
          (updatedOrder.payment_subtype === "atm" && updatedOrder.code_no) ||
          updatedOrder.display_state !== "payment_waiting"
        ) {
          setOrder(updatedOrder);
          setCartData(JSON.parse(updatedOrder.cart));
          await getItems(updatedOrder.id);
          setStatus(null);
          return { status: "success", message: "" };
        }

        await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
      } catch (error) {
        console.error("Error fetching order:", error);
        return { status: "error", message: "獲取訂單時發生錯誤" };
      }
    }

    return { status: "timeout", message: "請稍候重整或聯繫客服人員" };
  }, [getOrder, getItems]);

  useEffect(() => {
    const getInvoices = async id => {
      try {
        let resp = await Cart.Actions.getInvoicesByOrderId(id);
        setInvoices(resp.results);
      } catch (ex) {
        console.warn(ex);
      }
    };

    const getLogistics = async id => {
      try {
        let resp = await Cart.Actions.getLogisticsByOrderId(id); // for attachment upload
        setLogistics(resp.results);
      } catch (ex) {
        console.warn(ex);
      }
    };

    const fetchOrderData = async () => {
      app.actions.setLoading(true);
      let _order = await getOrder();
      if (_order) {
        await Promise.all([
          getItems(_order.id),
          getInvoices(_order.id),
          getLogistics(_order.id),
        ]);
      }
      app.actions.setLoading(false);
    };

    const handleSuccessStatus = async () => {
      const result = await waitForOrderStatusUpdate();
      switch (result.status) {
        case "success":
          await fetchOrderData();
          break;
        case "timeout":
          message.warning(result.message);
          break;
        case "error":
          message.error(result.message + "，請稍候再重整或聯繫客服人員。");
          break;
        default:
          break;
      }
    };

    if (status === "success") {
      handleSuccessStatus();
    } else {
      fetchOrderData();
    }
  }, [getOrder, getItems, waitForOrderStatusUpdate, status]);

  useEffect(() => {
    //tracking
    if (order && cartData && profile && id.indexOf("_") > 0) {
      // enter from rev-payment
      console.log("trigger tracking");
      const event_id = id;
      Pixel.purchase(order.amount, cartData.items, event_id);
      GA.purchase(cartData.items, order, event_id);
    }
  }, [order, profile, cartData, id]);

  const checkout = () => {
    if (typeof window !== "undefined") {
      const event_id =
        typeof crypto?.randomUUID === "function"
          ? crypto.randomUUID()
          : new Date().toISOString();

      Pixel.initCheckout(event_id);
      GA.checkout(order, cartData);

      window.open(
        `${Constants.apiUrl}/checkout/request/?order=${
          order.id
        }&token=${localStorage.getItem("token")}&event_id=${event_id}`,
        "_self"
      );
    }
  };

  if (!profile) {
    return <UnAuthResult />;
  }

  if (app.state.loading) {
    return (
      <Wrapper>
        <div className="center-content"> </div>
      </Wrapper>
    );
  }

  if (status === "success") {
    return (
      <Wrapper>
        <div className="center-content">訂單資料處理中...</div>
      </Wrapper>
    );
  }

  if (!order) {
    return (
      <Wrapper>
        <div className="center-content">
          {" "}
          <h1>無此訂單</h1>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <OrderBasicInfo
        key={order.payment_status}
        order={order}
        cartData={cartData}
        orderItems={orderItems}
        onUpdate={async () => {
          app.actions.setLoading(true);
          await getOrder();
          app.actions.setLoading(false);
        }}
      />
      <Container>
        <LeftColumn>
          {order.payment_status !== "success" && (
            <Payment
              order={order}
              checkout={checkout}
              profile={profile}
              onUpdate={async () => {
                app.actions.setLoading(true);
                await getOrder();
                app.actions.setLoading(false);
              }}
            />
          )}
          {["ass_apply", "refunding", "refunded"].includes(
            order.display_state
          ) && <AfterSales order={order} orderItems={orderItems} />}
          {order.order_type === "extra" ? (
            <Extra order={order} />
          ) : (
            <Products
              order={order}
              cartData={cartData}
              orderItems={orderItems}
              onUpdate={() => getItems(order.id)}
            />
          )}
          <Logistic order={order} logistics={logistics} />
        </LeftColumn>
        <RightColumn>
          <Calculation order={order} />
          <Invoice order={order} invoices={invoices} />
        </RightColumn>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #edeef1;
  padding-top: 46px;
  & > .cart-step {
    max-width: 1200px;
    margin: 0px auto;
  }
  & > .center-content {
    max-width: 1200px;
    margin: 0px auto;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  column-gap: 32px;
  padding-bottom: 48px;
  @media only screen and (max-width: ${Constants.breakpoints.xxl}px) {
    max-width: calc(100% - 64px);
  }
  @media only screen and (max-width: ${Constants.breakpoints.xl}px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    max-width: 100%;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 calc(100% - 32px - 488px);
  max-width: calc(100% - 32px - 488px);
  @media only screen and (max-width: ${Constants.breakpoints.xl}px) {
    flex: 0 0 auto;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 0 0 488px;
  max-width: 488px;
  @media only screen and (max-width: ${Constants.breakpoints.xl}px) {
    flex: 0 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;
    > * {
      margin-top: 24px;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(OrderDetailPage);
