import React, { useEffect, useContext, useState } from "react";
import Item from "./Item";
import styled from "styled-components";
import { Context } from "../../AppContext";

const Constants = require("../../constants");

function Products({ order, cartData, orderItems, onUpdate }) {
  const app = useContext(Context);
  const [spec, setSpec] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (cartData) {
        try {
          let spec = await app.actions.getSpec(cartData.version);
          setSpec(spec);

          // 獲取所有產品數據
          const productPromises = cartData.items.map(
            async (itemInCart, idx) => {
              try {
                let product = await app.actions.getProductFromSpec({
                  productName:
                    order?.order_type === "custom"
                      ? "自定義商品"
                      : itemInCart.name,
                  spec,
                });
                return { ...product, image: itemInCart.image };
              } catch (error) {
                console.error(`Error fetching product for item ${idx}:`, error);
                return null;
              }
            }
          );

          const fetchedProducts = await Promise.all(productPromises);
          setProducts(fetchedProducts.filter(Boolean));
        } catch (err) {
          console.error("Error fetching spec or products:", err);
        }
      }
    };

    fetchData();
  }, [app.actions, cartData, order?.order_type]);

  return (
    <Wrapper>
      <Header>
        商品稿件<ProductNums>{cartData.items.length}</ProductNums>
      </Header>
      <Content>
        {orderItems
          .sort((a, b) => a.item_index - b.item_index)
          .map(item => (
            <Item
              key={item.id}
              isCustomItem={order.order_type === "custom"}
              item={cartData.items[item.item_index]}
              product={products[item.item_index]}
              orderItem={item}
              onUpdate={onUpdate}
            />
          ))}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: #fff;
  margin-bottom: 24px;
  padding: 24px;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
`;

const ProductNums = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #edeef1;
  color: #000;
  margin-left: 8px;
  font-size: 16px;
`;

const Content = styled.div`
  padding: 10px 0;
`;

export default Products;
