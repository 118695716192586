import React from "react";
import { Block, RowText } from "../../Widgets";
import { message } from "antd";
import Expander from "./Expander";
import styled from "styled-components";
import {
  DELIVERY_TYPE,
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_SELF,
  STORE_CHANNEL,
  LOGISTIC_TRACKING_URL,
} from "../../dictionary";

const constants = require("../../constants");

const OrderRowText = props => <RowText useOrderLayout {...props} />;

function Logistic({ order, logistics }) {
  let cartData = JSON.parse(order.cart);

  const getTagStyle = logistic => {
    const status = logistic?.logistics_status;
    switch (status) {
      case "transit":
        return {
          background: "#fff4ed",
          color: `${constants.colors.highlight}`,
        };
      case "delivered":
        return {
          background: "#f0faf0",
          color: "#41ae49",
        };
      case "undelivered":
        return {
          background: "#fef6f6",
          color: "#e2341d",
        };
      default:
        return {
          background: "#f8f8f8",
          color: "#25272c",
        };
    }
  };

  const Tag = ({ logistic }) => {
    const wording =
      logistic?.logistics_type === "self_pick"
        ? LOGISTIC_STATUS_SELF[logistic.logistics_status]
        : LOGISTIC_STATUS[logistic?.logistics_status] ?? "尚未有物流單";

    return <StyledTag getTagStyle={getTagStyle()}>{wording}</StyledTag>;
  };

  const handleCopyButtonOnClick = async num => {
    try {
      await navigator.clipboard.writeText(num);
      message.success("物流追蹤碼已成功複製");
    } catch (err) {
      message.error("複製失敗，請再試一次");
    }
  };

  const DeliveryConfigInfo = () => (
    <>
      <OrderRowText
        containerStyle={{
          gridColumn: "1 / -1",
        }}
        label="運送方式"
        value={
          cartData.config?.deliveryConfig?.delivery_type
            ? DELIVERY_TYPE[cartData.config?.deliveryConfig?.delivery_type]
            : "資料尚未產生"
        }
      />
      {cartData.config?.deliveryConfig?.delivery_type === "hct" && (
        <>
          {cartData.config.deliveryConfig.is_delivery_private && (
            <>
              <OrderRowText
                label="保密代寄"
                value="是"
                containerStyle={{
                  gridColumn: "1 / -1",
                }}
              />
              <OrderRowText
                label="寄件人"
                value={cartData.config.deliveryConfig.sender_name}
              />
              <OrderRowText
                label="寄件人地址"
                value={`${cartData.config.deliveryConfig.sender_zip}${cartData.config.deliveryConfig.sender_city}${cartData.config.deliveryConfig.sender_district}${cartData.config.deliveryConfig.sender_address}`}
              />
              <OrderRowText
                label="寄件人手機"
                value={cartData.config.deliveryConfig.sender_phone}
              />
              <OrderRowText
                label="寄件人電話"
                value={`${cartData.config.deliveryConfig.sender_tel}${
                  cartData.config.deliveryConfig.sender_tel_ext &&
                  cartData.config.deliveryConfig.sender_tel_ext !== ""
                    ? ` # ${cartData.config.deliveryConfig.sender_tel_ext}`
                    : ""
                }`}
              />
              <OrderRowText
                label="寄件人備用電話"
                value={
                  cartData.config.deliveryConfig.receiver_phone_spare !== ""
                    ? cartData.config.deliveryConfig.receiver_phone_spare
                    : "-"
                }
              />
            </>
          )}

          <OrderRowText
            label="收件人"
            value={cartData.config.deliveryConfig.receiver_name}
          />
          <OrderRowText
            label="收件人地址"
            value={`${cartData.config.deliveryConfig.zip}${cartData.config.deliveryConfig.receiver_city}${cartData.config.deliveryConfig.receiver_district}${cartData.config.deliveryConfig.receiver_address}`}
          />
          <OrderRowText
            label="收件人手機"
            value={cartData.config.deliveryConfig.receiver_phone}
          />
          <OrderRowText
            label="收件人電話"
            value={`${cartData.config.deliveryConfig.receiver_tel}${
              cartData.config.deliveryConfig.receiver_tel_ext &&
              cartData.config.deliveryConfig.receiver_tel_ext !== ""
                ? ` # ${cartData.config.deliveryConfig.receiver_tel_ext}`
                : ""
            }`}
          />
          <OrderRowText
            label="收件人備用電話"
            value={
              cartData.config.deliveryConfig.receiver_phone_spare !== ""
                ? cartData.config.deliveryConfig.receiver_phone_spare
                : "-"
            }
          />
          <OrderRowText
            label="收件備註"
            value={cartData.config?.deliveryConfig?.delivery_note}
            containerStyle={{
              gridColumn: "1 / -1",
            }}
          />
        </>
      )}

      {["xdelivery", "payuni"].includes(
        cartData.config?.deliveryConfig?.delivery_type
      ) && (
        <>
          <OrderRowText
            label="通路"
            value={STORE_CHANNEL[cartData.config.deliveryConfig.st_state]}
          />
          <OrderRowText
            label="門市名稱"
            value={cartData.config.deliveryConfig.rstore_name}
          />
          <OrderRowText
            label="門市地址"
            value={cartData.config.deliveryConfig.rstore_addr}
          />
          <OrderRowText
            label="收件人"
            value={cartData.config.deliveryConfig.receiver_name}
          />
          <OrderRowText
            label="收件人手機"
            value={cartData.config.deliveryConfig.receiver_phone}
          />
        </>
      )}
    </>
  );

  const LogisticInfo = ({ logistic }) => (
    <>
      <OrderRowText
        containerStyle={{
          gridColumn: "1 / -1",
        }}
        label="運送方式"
        value={DELIVERY_TYPE[logistic.logistics_type]}
      />
      <OrderRowText
        label={
          ["xdelivery", "payuni", "ezship"].includes(logistic.logistics_type)
            ? "店到店單號"
            : "物流宅配單號"
        }
        value={logistic.tracking_number || "資料尚未產生"}
      />

      <LogisticTrackingButton
        href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
        target="_blank"
        rel="noreferrer"
      >
        貨況進度查詢
      </LogisticTrackingButton>

      <OrderRowText
        label={`預計${
          logistic.logistics_type === "self_pick" ? "可自取" : "出貨"
        }時間`}
        value={
          (logistic.shipping_time &&
            ` ${logistic.shipping_time.slice(0, 10)}`) ||
          "資料尚未產生"
        }
      />

      {logistic.receipt_time && (
        <OrderRowText
          label={`指定${
            logistic.logistics_type === "self_pick" ? "自取" : "收貨"
          }時間`}
          value={`${logistic.receipt_time.slice(
            0,
            10
          )} ${logistic.receipt_time.slice(11, 16)}`}
        />
      )}

      <OrderRowText
        label="資料最後更新時間"
        value={
          (logistic.updated &&
            ` ${logistic.updated.slice(0, 10)} ${logistic.updated.slice(
              11,
              16
            )}`) ||
          "資料尚未產生"
        }
      />

      {logistic.logistics_type === "hct" && (
        <>
          {logistic.is_delivery_private && (
            <>
              <OrderRowText
                containerStyle={{
                  gridColumn: "1 / -1",
                }}
                label="保密代寄"
                value="是"
              />
              <OrderRowText label="寄件人" value={logistic.sender_name} />
              <OrderRowText
                label="寄件人地址"
                value={`${logistic.sender_zip}${logistic.sender_city}${logistic.sender_district}${logistic.sender_address}`}
              />
              <OrderRowText label="寄件人手機" value={logistic.sender_phone} />
              <OrderRowText
                label="寄件人電話"
                value={`${logistic.sender_tel}${
                  logistic.sender_tel_ext && logistic.sender_tel_ext !== ""
                    ? ` # ${logistic.sender_tel_ext}`
                    : ""
                }`}
              />
            </>
          )}

          <OrderRowText label="收件人" value={logistic.receiver_name} />
          <OrderRowText
            label="收件人地址"
            value={`${logistic.zip_code}${logistic.receiver_city}${logistic.receiver_district}${logistic.receiver_address}`}
          />
          <OrderRowText label="收件人手機" value={logistic.receiver_phone} />
          <OrderRowText
            label="收件人電話"
            value={`${cartData.config.deliveryConfig.receiver_tel}${
              logistic.receiver_tel_ext && logistic.receiver_tel_ext !== ""
                ? ` # ${logistic.receiver_tel_ext}`
                : ""
            }`}
          />
          <OrderRowText
            label="收件人備用電話"
            value={
              logistic.receiver_phone_spare !== ""
                ? logistic.receiver_phone_spare
                : "-"
            }
          />
          <OrderRowText
            containerStyle={{
              gridColumn: "1 / -1",
            }}
            label="收件備註"
            value={logistic.logistics_note}
          />
        </>
      )}

      {["xdelivery", "payuni"].includes(logistic.logistics_type) && (
        <>
          <OrderRowText label="通路" value={STORE_CHANNEL[logistic.st_state]} />
          <OrderRowText label="門市名稱" value={logistic.rstore_name} />
          <OrderRowText label="門市地址" value={logistic.rstore_addr} />
          <OrderRowText label="收件人" value={logistic.receiver_name} />
          <OrderRowText label="收件人手機" value={logistic.receiver_phone} />
        </>
      )}
    </>
  );

  return (
    <StyledBlock
      title={"運送資訊"}
      size={`${constants.fontSize.md}px`}
      fontWeight={700}
      height={"30px"}
      lineHeight={"30px"}
      padding={"0px"}
      wrapperPadding={"24px"}
      bg={constants.colors.background}
      color={constants.colors.text}
    >
      {logistics.length > 0 ? (
        logistics.map((logistic, index) => (
          <Expander
            title="物流單編號"
            StatusComponent={() => <Tag logistic={logistic} />}
            expanderNumber={logistic.id}
          >
            <Grid>
              <LogisticInfo logistic={logistic} />
            </Grid>
          </Expander>
        ))
      ) : (
        <Expander title="物流單編號" StatusComponent={() => <Tag />}>
          <Grid>
            <DeliveryConfigInfo />
          </Grid>
        </Expander>
      )}
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: ${props => props.getTagStyle.background};
  color: ${props => props.getTagStyle.color};
  font-size: 16px;
  font-weight: 500;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 122px auto;
  gap: 16px 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`;

const LogisticTracking = styled.div`
  grid-column: 1 / -1;
  h3 {
    font-size: 14px;
    color: #4a4e5a;
    margin-bottom: 4px;
  }
  div {
    display: flex;
    align-items: center;
    .numberWrapper {
      position: relative;
      padding: 4px 8px;
      border: 1px solid #8e95a2;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 500;
      color: #25272c;
      margin-right: 8px;
      width: 172px;
      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        cursor: pointer;
      }
    }
    a {
      color: ${constants.colors.highlight};
      font-weight: 500;
    }
  }
`;

const LogisticTrackingButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 40px;
  background-color: ${constants.colors.highlight};
  font-size: 14px;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  &:hover {
    color: white;
  }
`;

export default Logistic;
