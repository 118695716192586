import React, { useRef, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
const constants = require("../../constants");

const STEPS = {
  payment: 0,
  review: 1,
  production: 2,
  logistic: 3,
  completed: 4,
};

const STEP_INFO = [
  {
    icon: "attach_money.svg",
    text: "待付款",
    completedText: "已付款",
  },
  {
    icon: "review.svg",
    text: "審稿階段",
    completedText: "審稿完成",
  },
  {
    icon: "prepare.svg",
    text: "製作階段",
    completedText: "製作完成",
  },
  {
    icon: "deliever.svg",
    text: "出貨階段",
    completedText: "出貨完成",
  },
  {
    icon: "arrive.svg",
    text: "待取件",
    completedText: "訂單完成",
  },
];

const REFUND_STEPS = [
  {
    icon: "search.svg",
    text: "待審核",
    completedText: "審核通過",
  },
  {
    icon: "refund.svg",
    text: "待退款",
    completedText: "已退款",
  },
  {
    icon: "close.svg",
    text: "待取消",
    completedText: "已取消",
  },
];

const OrderStepTabs = ({
  orderState,
  orderDate,
  paymentStatus,
  requireDate,
  requireDateWithProof,
}) => {
  const tabBarRef = useRef(null);
  const pointRef = useRef(null);
  const [lineWidth, setLineWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const { currentSteps, currentStep, isRefundMode } = useMemo(() => {
    const refundStates = ["ass_apply", "refunding", "refunded", "voided"];
    const isRefundMode = refundStates.includes(orderState);

    if (isRefundMode) {
      let step;
      switch (orderState) {
        case "ass_apply":
          step = 0;
          break;
        case "refunding":
          step = 1;
          break;
        case "refunded":
        case "voided":
          step = 2;
          break;
        default:
          step = 0;
      }
      return {
        currentSteps: REFUND_STEPS,
        currentStep: step,
        isRefundMode: true,
      };
    } else {
      const statePrefix = orderState.split("_")[0];
      let step = STEPS[statePrefix] || 0;

      if (paymentStatus === "success" && step === STEPS.payment) {
        step = STEPS.review;
      }

      return {
        currentSteps: STEP_INFO,
        currentStep: step,
        isRefundMode: false,
      };
    }
  }, [orderState, paymentStatus]);

  const isAllCompleted =
    orderState === "completed" ||
    orderState === "voided" ||
    orderState === "refunded";

  useEffect(() => {
    const updateDimensions = () => {
      setIsMobile(window.innerWidth < 768);
      if (tabBarRef.current && pointRef.current) {
        const tabBarWidth = tabBarRef.current.offsetWidth;
        const stepCount = currentSteps.length;
        const pointWidth = pointRef.current.offsetWidth;
        const totalPointWidth = pointWidth * stepCount;
        const availableWidth = tabBarWidth - totalPointWidth;
        const calculatedLineWidth = availableWidth / (stepCount - 1);
        setLineWidth(Math.max(calculatedLineWidth, 0));
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [currentSteps]);

  if (isMobile) {
    return (
      <MobileTabWrapper>
        <MobileTabItem isCompleted={isAllCompleted} isRefundMode={isRefundMode}>
          <div className="point">
            <img
              src={`/images/order/steps/${currentSteps[currentStep].icon}`}
              alt=""
            />
          </div>
          <div className="text">
            {isAllCompleted
              ? currentSteps[currentStep].completedText
              : currentSteps[currentStep].text}
          </div>
        </MobileTabItem>
        <OrderDate>
          <div>
            <h4>
              <img src="/images/order/clock.svg" alt="" />
              下單日期
            </h4>
            <p>{moment(orderDate).format("YYYY/MM/DD")}</p>
          </div>
          <div>
            <h4>
              <img src="/images/order/shipping.svg" alt="" />
              預計出貨日期
            </h4>
            <p style={{ fontWeight: "700" }}>
              {requireDate === requireDateWithProof
                ? requireDate
                : `${requireDate}~${requireDateWithProof}`}
            </p>
          </div>
        </OrderDate>
      </MobileTabWrapper>
    );
  }

  return (
    <TabBar ref={tabBarRef}>
      {currentSteps.map((item, index) => (
        <TabItem
          key={index}
          isActived={currentStep >= index}
          isCurrentStep={currentStep === index}
          lineWidth={lineWidth}
          nextIsActived={currentStep > index}
          isAllCompleted={isAllCompleted}
          isRefundMode={isRefundMode}
        >
          <div className="point" ref={index === 0 ? pointRef : null}>
            <img src={`/images/order/steps/${item.icon}`} alt="" />
          </div>
          {isAllCompleted || currentStep > index
            ? item.completedText
            : item.text}
        </TabItem>
      ))}
    </TabBar>
  );
};

const TabBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 24px;
`;

const TabItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${props =>
    props.isAllCompleted
      ? props.isRefundMode
        ? "#FF4D4F"
        : "#41AE49"
      : props.isActived
      ? constants.colors.highlight
      : "#6B7280"};

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    height: 4px;
    width: ${props => props.lineWidth}px;
    top: 32px;
    left: 100%;
    background-color: ${props =>
      props.isAllCompleted
        ? props.isRefundMode
          ? "#FF4D4F"
          : "#41AE49"
        : props.nextIsActived
        ? constants.colors.highlight
        : "#B6BAC3"};
    z-index: 0;
  }

  & > .point {
    z-index: 1;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 50%;
    background-color: ${props =>
      props.isAllCompleted
        ? props.isRefundMode
          ? "#FF4D4F"
          : "#41AE49"
        : props.isActived
        ? constants.colors.highlight
        : "#B6BAC3"};

    & > img {
      display: inline-block;
      width: 36px;
    }
  }
`;

const MobileTabWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 24px 0;
  background-color: white;
  margin-top: 24px;
`;

const MobileTabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: ${props =>
    props.isCompleted
      ? props.isRefundMode
        ? "#FF4D4F"
        : "#41AE49"
      : constants.colors.highlight};
  & > .point {
    z-index: 1;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${props =>
      props.isAllCompleted
        ? props.isRefundMode
          ? "#FF4D4F"
          : "#41AE49"
        : constants.colors.highlight};

    & > img {
      display: inline-block;
      width: 36px;
    }
  }
  & > .text {
    font-size: ${constants.fontSize.md}px;
    font-weight: bold;
  }
`;

const OrderDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  > div {
    display: block;
    text-align: center;
    flex: 0 0 50%;
    max-width: 50%;
    &:first-child {
      border-right: 1px solid #b6bac3;
    }
    &:last-child {
      color: ${constants.colors.highlight};
      h4 {
        color: ${constants.colors.highlight};
      }
    }
    h4 {
      display: inline-flex;
      align-items: center;
      font-size: ${constants.fontSize.sm}px;
      line-height: normal;
      img {
        margin-right: 4px;
      }
    }
    p {
      font-weight: bold;
      margin-bottom: 0;
    }
  }
`;

export default OrderStepTabs;
