import React, { useState, useMemo, useCallback, useEffect } from "react";
import OrderStepTabs from "./OrderStepTabs";
import PdfDownloadButton from "../PdfDownloadButton";
import VoidDialog from "../VoidOrderDialog";
import ReturnAppDialog from "../ReturnApplicationDialog";
import styled from "styled-components";
import moment from "moment";
import { message } from "antd";
import { navigate } from "gatsby";
import { calculateEstimatedShipmentDate } from "../../Utils/requireDayUtil";
import * as Cart from "../../Contexts/CartContext";
const Constants = require("../../constants");

function OderBasicInfo({ order, orderItems, cartData, onUpdate }) {
  const [openVoidDialog, setOpenVoidDialog] = useState(false);
  const [returnApps, setReturnApps] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [openReturnAppDialog, setOpenReturnAppDialog] = useState(false);
  const [requireDate, setRequireDate] = useState("");
  const [requireDateWithProof, setRequireDateWithProof] = useState("");
  const [isProductCompleted, setIsProductCompleted] = useState(false);

  useEffect(() => {
    const checkedProductDisplayState = order.display_state.split("_")[0];
    setIsProductCompleted(
      checkedProductDisplayState === "ass" ||
        checkedProductDisplayState === "refunding" ||
        checkedProductDisplayState === "refunded" ||
        checkedProductDisplayState === "voided" ||
        checkedProductDisplayState === "completed"
    );
  }, [order]);

  const handleCopyButtonOnClick = async () => {
    try {
      await navigator.clipboard.writeText(order.display_id);
      message.success("訂單編號已成功複製");
    } catch (err) {
      message.error("複製失敗，請再試一次");
    }
  };

  const handleBackButtonOnClick = () => {
    navigate(-1);
  };

  const { orderItem, refund, returnApp } = useMemo(() => {
    return {
      orderItem: orderItems && orderItems.length > 0 && orderItems[0],
      refund: Array.isArray(refunds) && refunds.length > 0 ? refunds[0] : null,
      returnApp:
        Array.isArray(returnApps) && returnApps.length > 0
          ? returnApps[0]
          : null,
    };
  }, [orderItems, refunds, returnApps]);

  const getRefunds = useCallback(async () => {
    try {
      let resp = await Cart.Actions.getRefundsByOrderId(order.id);
      setRefunds(resp.results);
    } catch (ex) {
      console.warn(ex);
    }
  }, [order]);

  const getReturnApps = useCallback(async () => {
    try {
      let resp = await Cart.Actions.getReturnAppsByOrderId(order.id);
      setReturnApps(resp.results);
    } catch (ex) {
      console.warn(ex);
    }
  }, [order]);

  useEffect(() => {
    getRefunds();
    getReturnApps();
  }, [getRefunds, getReturnApps]);

  useEffect(() => {
    const maxRequireDateStr = calculateEstimatedShipmentDate({
      order: order,
      cartItems: cartData.items,
    });
    setRequireDate(maxRequireDateStr);
    const maxRequireDateWithProofStr = calculateEstimatedShipmentDate({
      order: order,
      cartItems: cartData.items,
      proofingDays: 4,
    });
    setRequireDateWithProof(maxRequireDateWithProofStr);
  }, [order]);

  return (
    <Wrapper>
      <InfoBar>
        <OrderID>
          <img
            src="/images/order/arrow_back.svg"
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleBackButtonOnClick}
          />
          {order.order_type === "credit" ? "儲值編號：" : "訂單編號："} #{" "}
          {order.display_id}
          <CopyButton
            src="/images/order/copy.svg"
            alt=""
            onClick={handleCopyButtonOnClick}
          />
        </OrderID>
        <ActionBtnSection>
          <PdfDownloadButton order={order} />
          {order.payment_type !== "monthly" &&
            order.payment_status !== "success" &&
            !order.voided && (
              <Button onClick={() => setOpenVoidDialog(true)}>取消訂單</Button>
            )}
          {returnApps.length === 0 &&
            order.payment_status === "success" &&
            order.display_state === "completed" &&
            moment().diff(order.complete_time, "days") < 3 &&
            !["refunded", "voided"].includes(order.display_state) && (
              <Button onClick={() => setOpenReturnAppDialog(true)}>
                {" "}
                申請售後單
              </Button>
            )}
        </ActionBtnSection>
        <OrderDate isProductCompleted={isProductCompleted}>
          <div>
            <img src="/images/order/clock.svg" alt="" />
            <p style={{ margin: "0 8px 0 4px", color: "#5B616E" }}>下單日期</p>
            <span style={{ fontWeight: "bold" }}>
              {moment(order.created).format("YYYY/MM/DD")}
            </span>
          </div>
          <span
            style={{
              display: "inline-block",
              fontSize: "16px",
              margin: "0 16px",
              color: "#B6BAC3",
            }}
          >
            |
          </span>
          {isProductCompleted ? (
            <div>
              <img src="/images/order/shipping.svg" alt="" />
              <p style={{ margin: "0 8px 0 4px" }}>訂單已完成</p>
              <span style={{ fontWeight: "700" }}>{requireDate}</span>
            </div>
          ) : (
            <div>
              <img src="/images/order/shipping.svg" alt="" />
              <p style={{ margin: "0 8px 0 4px" }}>預計出貨日期</p>
              <span style={{ fontWeight: "700" }}>
                {requireDate === requireDateWithProof
                  ? requireDate
                  : `${requireDate}~${requireDateWithProof}`}
              </span>
            </div>
          )}
        </OrderDate>
      </InfoBar>
      <Steps>
        {["default", "extra", "monthly"].includes(order.order_type) && (
          <OrderStepTabs
            orderState={order?.display_state}
            orderDate={order?.created}
            requireDate={requireDate}
            requireDateWithProof={requireDateWithProof}
            paymentStatus={order?.payment_status}
          />
        )}
      </Steps>
      <VoidDialog
        show={openVoidDialog}
        order={order}
        cartData={cartData}
        closeDialog={() => setOpenVoidDialog(false)}
        refresh={onUpdate}
      />
      {openReturnAppDialog && (
        <ReturnAppDialog
          cartData={cartData}
          orderItem={orderItem}
          closeDialog={() => setOpenReturnAppDialog(false)}
          onUpdate={() => {
            getReturnApps();
            onUpdate();
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #edeef1;
  margin-bottom: 48px;
  max-width: 1200px;
  margin: 0px auto 48px;
  @media only screen and (max-width: ${Constants.breakpoints.xxl}px) {
    max-width: calc(100% - 64px);
  }
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    max-width: 100%;
    margin-bottom: 24px;
  }
`;

const InfoBar = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    margin: 0 24px;
  }
`;

const OrderID = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${Constants.fontSize.lg}px;
  font-weight: bold;
  color: #25272c;
  @media only screen and (max-width: ${Constants.breakpoints.lg}px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    font-size: ${Constants.fontSize.md}px;
    line-height: normal;
  }
`;

const OrderDate = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 24px;
  margin: 24px 0 32px;
  > div {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
  }

  & > div:last-child {
    color: ${props =>
      props.isProductCompleted ? "#41AE49" : Constants.colors.highlight};

    img {
      filter: ${props =>
        props.isProductCompleted &&
        "invert(49%) sepia(73%) saturate(454%) hue-rotate(74deg) brightness(103%) contrast(79%)"};
    }
  }

  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    display: none;
  }
`;

const CopyButton = styled.img`
  display: inline-block;
  cursor: pointer;
  padding-left: 4px;
  @media only screen and (max-width: ${Constants.breakpoints.lg}px) {
    margin-left: auto;
  }
`;

const Steps = styled.div``;

const Button = styled.div`
  padding: 10px 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 12px;
  text-align: center;
  background-color: ${Constants.buttons.background.normal.outline};
  color: ${Constants.buttons.background.normal.primary};
  border: 1px solid ${Constants.buttons.background.normal.primary};
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-color: ${Constants.buttons.background.hover.outline};
  }

  ${props => props.css}
`;

const ActionBtnSection = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  @media only screen and (max-width: ${Constants.breakpoints.lg}px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 16px;
  }
`;

export default OderBasicInfo;
